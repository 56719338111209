import React from 'react';
import '../../styles/_header.scss';

const Header = () => (
    <div className="main-header">
        STEVEN LI
    </div>
);

export default Header;
